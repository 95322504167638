import React, { useEffect, useState } from 'react';
import BasicModal from '../components/BasicModal';
import MarketingHead from '../components/marketing-meta';
import MarketingFooter from '../components/MarketingFooter';
import Navbar from '../components/NavBar';

const TrackingPage = () => {
    const [salesModal, setSalesModal] = useState(false);


    // load the HS script
    useEffect(() => {
      const script = document.createElement('script');
      script.src = '//js.hsforms.net/forms/shell.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
  
      })
  
    }, []);
  
    // populate a lil form
    useEffect(() => {
      if(window.hbspt && salesModal) {
        window.hbspt.forms.create({
          portalId: '14557468',
          formId: '2be2ed45-2b95-4eb4-9301-40856c8cb10d',
          target: '#hubspotForm'
        })
      }
    }, [salesModal])

    return (
        <>
            <MarketingHead />
            <title>Kodama - Logistics Solutions</title>
            <BasicModal title="Join the brokerage beta!" isOpen={salesModal} close={() => setSalesModal(false)}>
                Interested in selling carbon credits through Kodama?
                <div id="hubspotForm" className="mt-2"/>
            </BasicModal>
            <div className={'bg-hero-image bg-cover p-1 text-white'}>
                <Navbar /> 
                <div className="container xl:px-40 mt-10 mb-8 mx-auto">
                    <div className="min-h-96 p-2 w-full my-12">
                        <p className="text-5xl mb-8 font-bold">Kodama for Logistics</p>
                        {/* <div className="px-4 mt-6 mx-2 border-l w-2/3 border-white"> */}
                        <p className="mt-2 text-lg">Track and manage the carbon emissions of your logistics company using Kodama.</p>
                        <p className="mt-2 text-lg">Understand the total carbon impact of your business by integrating with your existing TMS.</p>
                        <p className="mt-2 text-lg">Use Kodama to give your customers or shippers ways to offset their carbon emissions created through transit.</p>
                        {/* </div> */}
                        <button onClick={() => setSalesModal(true)} className="border border-white rounded-md text-white px-4 py-2 mt-4">
                        Join the logistics beta
                        </button>
                    </div>          
                </div> 
            </div>

            <div className="container xl:px-40 mt-24 mb-5 mx-auto grid grid-cols-1 lg:grid-cols-2 gap-4 items-center  px-4 lg:px-0">
                <div>
                    <h1 className="text-5xl font-bold text-primary">Help your shippers and customers offset their emissions</h1>
                </div>
                <div className="border-l border-primary pl-4">
                    <p className="text-lg mt-2 text-gray-700">
                        Integrate Kodama into your existing infrastructure and TMS, and give your customers the ability to offset their emissions. Integrate Kodama, set the price of adding offsets, and give your customers a way to save the planet.
                    </p>
                </div>
            </div>
            
            <div className="container xl:px-40 mt-32 mb-5 px-4 lg:px-0 lg:mx-auto">
               <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 bg-gray-50 shadow-lg border border-gray-200 p-8 rounded-lg">
                   <div className="mb-4 lg:mb-0">
                       <div className="inline-block">
                        <div className="bg-green-100 text-primary rounded-full w-10 h-10 flex items-center justify-center mb-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                            </svg>
                        </div>
                        <h2 className="text-xl font-bold text-primary">Sell offsets, add value</h2>
                       </div>
                       <p className="mt-4 leading-8">
                        Customers are more likely to support businesses with a cause. Selling carbon offsets increases customer and employee retention, and creates a new value add for your business
                        and customers.
                       </p>
                   </div>
                   <div className="mb-4 lg:mb-0">
                       <div className="inline-block">
                        <div className="bg-green-100 text-primary rounded-full w-10 h-10 flex items-center justify-center mb-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                            </svg>
                        </div>
                        <h2 className="text-xl font-bold text-primary">Integrate into your workflow</h2>
                       </div>
                       <p className="mt-4 leading-8">
                            Kodama's API gives you the ability to add carbon offsets into your existing suite of software. Kodama's API is easily integrated into 
                            your existing TMS, CRMs, or other logistics management software.
                        </p>
                   </div>
                   <div className="mb-4 lg:mb-0">
                       <div className="inline-block">
                        <div className="bg-green-100 text-primary rounded-full w-10 h-10 flex items-center justify-center mb-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                            </svg>
                        </div>
                        <h2 className="text-xl font-bold text-primary">Real time data for you and your customers</h2>
                       </div>
                       <p className="mt-4 leading-8">
                           Show your customers and stakeholders realtime data about how customers are leveraging carbon offsets. Gain insights into your emissions overtime, and how customers are leveraging carbon offsets.
                       </p>
                   </div>
               </div>
                
            </div>
            <div className="container xl:px-40 mt-24 mb-5 mx-auto grid grid-cols-1 lg:grid-cols-2 gap-4 items-center  px-4 lg:px-0">
                <div className="order-first lg:order-last border border-green-600 bg-primary p-8 rounded-lg shadow-lg">
                    <h1 className="text-5xl font-bold text-white">The average cost to offset a truckload is just $17</h1>
                    <p className="mt-2 text-white">Kodama gives you the tools to estimate, track, and offset loads automatically.</p>
                </div>
                <div className="pr-4">
                    <p className="text-lg mt-2 text-gray-700">
                        Use Kodama to give your customers a new product offering, or take sustainability into your own hands and offset independently. Track the entire impact of your fleet, brokerage, or shipping operations and audit your GHG emissions.
                    </p>
                </div>
            </div>
      <MarketingFooter />
      </>
    )
}

export default TrackingPage;
